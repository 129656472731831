<template>
    <div class="assoc-item">
        <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <el-input size="large" :value="item" :disabled="true"></el-input>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="arrow-el">
                <svg-icon icon-class="arrow-down" />
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <c-select @selected="categorySelected" :categ="selectedCategory"></c-select>
                <el-alert
                    v-if="invalidCategory"
                    title="Categoria aleasa este invalida. Verificati daca este permis accesul la categorie."
                    type="error"
                    show-icon
                    :closable="false"
                >
                </el-alert>
            </el-col>
        </el-row>
    </div>
</template>
<script>
const CategorySelect = () => import('@/components/CategoriesSelect');
import { mapState } from 'vuex';
import md5 from 'md5';

export default {
    name: 'feed-categ-assoc',
    props: ['item'],
    components: {
        'c-select': CategorySelect
    },
    data() {
        return {
            invalidCategory: false
        };
    },
    methods: {
        categorySelected(categ) {
            if (parseInt(categ.categories_id) !== 0) {
                this.invalidCategory = false;
                this.$store.commit('feeds/SET_CATEGORY_ASSOC', { field: this.item, categ: categ.categories_id });
            } else {
                this.$store.commit('feeds/REMOVE_CATEGORY_ASSOC', this.item);
                this.invalidCategory = true;
            }
        }
    },
    computed: {
        ...mapState({
            categoryAssoc: (state) => state.feeds.categoryAssoc
        }),
        selectedCategory() {
            return typeof this.categoryAssoc[this.item] !== 'undefined' &&
                parseInt(this.categoryAssoc[this.item]) !== 0
                ? this.categoryAssoc[this.item]
                : 0;
        }
    },
    created() {}
};
</script>

<style lang="scss">
@import './src/styles/modules/feeds.scss';

.arrow-el {
    text-align: center;
}
</style>
